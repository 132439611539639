<template>
  <a-card :bordered="false">
    <div class="table-page-search-wrapper">
      <a-form layout="inline">
        <a-row :gutter="48">
          <a-col :md="5" :sm="15">
            <a-form-item label="申请标题">
              <a-input placeholder="请输入申请标题" v-model="queryParam.title" allowClear />
            </a-form-item>
          </a-col>
          <a-col :md="5" :sm="15">
            <a-form-item label="状态">
              <a-select placeholder="请选择" v-model="queryParam.status" default-value="0" allowClear>
                <a-select-option :value="''">全部</a-select-option>
                <a-select-option v-for="(val, key) in statusMap" :key="key" :value="key">{{ val }}</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :md="5" :sm="15">
            <a-form-item label="审批结果">
              <a-select placeholder="请选择" v-model="queryParam.result" default-value="0" allowClear>
                <a-select-option :value="''">全部</a-select-option>
                <a-select-option v-for="(val, key) in resultMap" :key="key" :value="key">{{ val }}</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>

          <a-col :md="8" :sm="24">
            <span class="table-page-search-submitButtons">
              <a-button type="primary" @click="query">查询</a-button>
<!--              <a-button style="margin-left: 8px" @click="() => (queryParam = {})">重置</a-button>-->
              <a-button style="margin-left: 8px" @click="reset">重置</a-button>
            </span>
          </a-col>
        </a-row>
      </a-form>
    </div>
    <div class="table-operator">
      <a-button type="primary" icon="plus" @click="$refs.modal.add()">新建</a-button>
      <a-dropdown v-if="selectedRowKeys.length > 0">
        <a-button type="danger" icon="delete" @click="delByIds(selectedRowKeys)">删除</a-button>
      </a-dropdown>
    </div>

    <!-- 数据表格 -->
    <a-table
      :loading="tableLoading"
      :row-selection="rowSelection"
      :rowKey="(record) => record.id"
      :columns="columns"
      :data-source="tableData"
      bordered
      :pagination="page"
      :rowClassName="rowClassName"
      @change="changeTable"
    >
      <span slot="status" slot-scope="text">
        {{ text | statusFilter }}
      </span>
      <span slot="result" slot-scope="text">
        {{ text | resultFilter }}
      </span>
      <span slot="name" slot-scope="text">
        <a-tooltip>
          <template slot="title"> {{ text }} </template> {{ text }}
        </a-tooltip>
      </span>
      <span slot="index" slot-scope="text, row, index">{{ index + 1 }}</span>
      <span slot="logo" slot-scope="item">
        <ZoomMedia style="height: 20px" :value="item"></ZoomMedia>
      </span>

      <span slot="action" slot-scope="text, record">
        <a @click="handelAudit(record)">详情</a>
        <a-divider type="vertical" />
        <a :disabled="record.status !== 1" @click="cancel([record.procInstId])">撤销</a>
        <a-divider type="vertical" />
        <a :disabled="record.status === 1" @click="delByIds([record.id])">删除</a>
      </span>
    </a-table>

    <business-modal ref="modal" @ok="handleOk" />
    <!--    <leave-modal ref="leavemodal" @ok="handleOk" :audit="false"/>false-->
    <!--    <purchase-modal ref="purchasemodal" @ok="handleOk" :audit="false"/>-->
  </a-card>
</template>

<script>
import { STable } from '@/components'
import { getBusinessListMy, delProcessIns, delBusiness, modalMap, resultMap, statusMap } from '@/api/activiti'
import BusinessModal from './modules/BusinessModal.vue'
// import LeaveModal from './modules/LeaveModal.vue'
// import PurchaseModal from './modules/PurchaseModal.vue'

export default {
  name: 'TableList',
  components: {
    STable,
    BusinessModal,
    // LeaveModal,
    // PurchaseModal
  },
  data() {
    return {
      tableData: [],
      page: {
        current: 1,
        pageSize: 10,
        total: 0,
        showSizeChanger: true,
        showTotal: () => `共${this.page.total}条`,
      },
      tableLoading: false, //表格loading
      description: '',
      labelCol: {
        xs: { span: 24 },
        sm: { span: 5 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
      },

      form: this.$form.createForm(this),
      mdl: {},
      // 高级搜索 展开/关闭
      advanced: false,

      // 查询参数
      queryParam: {},
      // 表头
      columns: [
        {
          title: '申请标题',
          dataIndex: 'title',
        },
        {
          title: '所属流程',
          dataIndex: 'procName',
        },
        {
          title: '当前审批环节',
          dataIndex: 'currentTask',
        },
        {
          title: '审批结果',
          dataIndex: 'result',
          scopedSlots: { customRender: 'result' },
        },
        {
          title: '状态',
          dataIndex: 'status',
          scopedSlots: { customRender: 'status' },
        },
        {
          title: '申请时间',
          dataIndex: 'applyTime',
          sorter: true,
        },
        {
          title: '操作',
          width: '200px',
          dataIndex: 'action',
          scopedSlots: { customRender: 'action' },
        },
      ],
      selectedRowKeys: [],
      selectedRows: [],
      resultMap,
      statusMap,
      firstActed: true,
    }
  },
  computed: {
    rowSelection() {
      return {
        selectedRowKeys: this.selectedRowKeys,
        onChange: this.onSelectChange,
        getCheckboxProps: (record) => ({
          props: {
            disabled: record.status === 1, // Column configuration not to be checked
            // name: record.name
          },
        }),
      }
    },
  },
  filters: {
    statusFilter(status) {
      return statusMap[status]
    },
    resultFilter(status) {
      return resultMap[status]
    },
  },
  created() {
    this.getData()
  },
  activated() {
    if (this.firstActed) {
      this.firstActed = false
    } else {
      this.handleOk()
    }
  },
  methods: {
    // 隔行换色
    rowClassName(record, index) {
      let className = 'light-row'
      if (index % 2 === 1) className = 'dark-row'
      return className
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
    },
    handleAdd() {
      this.$refs.modal.add()
    },
    handelAudit(record) {
      const modal = modalMap[record.procDefKey]
      this.$refs[modal].show({ businessKey: record.id })
    },
    handleOk() {
      this.getData()
      console.log('handleSaveOk')
    },
    // 撤销
    cancel(ids) {
      const _that = this
      _that.$confirm({
        title: '确认撤销',
        content: '确认要主动撤销选中的申请?',
        onOk() {
          // const idstr = ids.map(r => { return '\'' + r + '\'' }).join('')
          delProcessIns({ ids: ids.join(','), reason: '主动撤销' }).then((res) => {
            if (res.code === 200) {
              _that.$message.success('撤销成功')
              _that.handleOk()
            } else {
              _that.$message.error(res.msg)
            }
            _that.selectedRowKeys = []
          })
        },
        onCancel() {},
      })
    },
    delByIds(ids) {
      const _that = this
      _that.$confirm({
        title: '确认删除',
        content: '确认要删除选择的申请?',
        onOk() {
          // const idstr = ids.map(r => { return '\'' + r + '\'' }).join('')
          delBusiness({ ids: ids.join(',') }).then((res) => {
            if (res.code === 200) {
              _that.$message.success('删除成功')
              _that.handleOk()
            } else {
              _that.$message.error(res.msg)
            }
            _that.selectedRowKeys = []
          })
        },
        onCancel() {},
      })
    },

    /**
     * 更改分页
     */
    changeTable(pagination) {
      this.page = pagination
      this.getData()
    },
    // 查询
    query(){
      this.page.current = 1;
      this.getData()
    },

    // 重置
    reset(){
      this.queryParam = {}
      this.page.current = 1;
      this.page.pageSize = 10;
      this.getData()
    },

    getData() {
      this.selectedRows = []
      this.selectedRowKeys = []
      this.tableLoading = true
      return getBusinessListMy({
        pageNumber: this.page.current,
        pageSize: this.page.pageSize,
        ...this.queryParam,
      })
        .then((res) => {
          if (res.code === 200) {
            const { total, records } = res.body
            this.page.total = total
            this.tableData = records
          }
        })
        .finally(() => (this.tableLoading = false))
    },
  },

  watch: {
    /*
      'selectedRows': function (selectedRows) {
        this.needTotalList = this.needTotalList.map(item => {
          return {
            ...item,
            total: selectedRows.reduce( (sum, val) => {
              return sum + val[item.dataIndex]
            }, 0)
          }
        })
      }
      */
  },
}
</script>

<style lang="less" scoped>
::v-deep .ant-table-tbody > .light-row {
  background: #fff !important;
}
::v-deep .ant-table-tbody > .dark-row {
  background: #fafafa !important;
}
</style>